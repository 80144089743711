require("./plugins/bootstrap.js");
require("./plugins/magnific-popup.js");
require("./plugins/bootstrap-datepicker.js");
require("./modules/sliders");

require("./modules/forms.js");

if ($("#maps").length) {
  require("./modules/location.js");
}

$(".js-gallery").each(function() {
  $(this).magnificPopup({
    delegate: "a",
    type: "image",
    gallery: {
      enabled: true,
      navigateByImgClick: true
    }
  });
});

$("a[data-target]").on("click", function(e) {
  e.preventDefault();

  var href = $(this).data("target");
  var target = $('[data-anchor="' + href + '"]');
  var delta = target.offset().top - 100;
  $("html, body").animate(
    {
      scrollTop: delta
    },
    500
  );
});
